import {appSettings} from './appSettings.js';
export const firebase = require("firebase/app");

// Required for side-effects
require("firebase/firestore");
require("firebase/database");
require("firebase/auth");
//const admin = require('firebase-admin');
//const functions = require('firebase-functions');
//import firebaseNative from 'react-native-firebase';


// Initialize Firebase
/*
var config = {
  apiKey: "AIzaSyAjhlRlCPnkuVU-AHhI0Vq3Pi3WanYB37k",
  authDomain: "lunsjboks-ee4fc.firebaseapp.com",
  databaseURL: "https://lunsjboks-ee4fc.firebaseio.com",
  projectId: "lunsjboks-ee4fc",
  storageBucket: "lunsjboks-ee4fc.appspot.com",
  messagingSenderId: "507131344962"
};
*/

//admin.initializeApp(config);
const fbApp = firebase.initializeApp(appSettings.firebase.config);

// Initialize Cloud Firestore through Firebase
var db = firebase.firestore();

// Disable deprecated features
/*
db.settings({
  timestampsInSnapshots: true
});
*/

//console.log(appSettings.firebase.fbDbRef);
export const firebaseApp = fbApp;
export const connectedRef = firebase.database().ref('.info/connected');
export const fbDbCol = db.collection(appSettings.firebase.fbDbCol);
export const firebaseAuth = firebase.auth();
export const fbDbRef = firebase.database().ref(appSettings.firebase.fbDbRef);

export function fbSignIn(userName, password){
  firebase.auth().signInWithEmailAndPassword(userName, password)
    .then(function(user){
      // Success 
      //alert("ok");
    })
  .catch(function(err) {
    // Error
    //alert(err);
  });
}


//export const firestore = admin.firestore();


