//import * as maptalks from 'maptalks';
//import {urlTemplates} from './maptalksUrlTemplates.js';

export const appSettings = {
    appName: 'TVSider',
    firebase : {
        config : {
            apiKey: "AIzaSyAJgvaDxKGMSkj1EBmeF7jQ1PUeI4EaD6Q",
            authDomain: "tvsidene.firebaseapp.com",
            databaseURL: "https://tvsidene.firebaseio.com",
            projectId: "tvsidene",
            storageBucket: "",
            messagingSenderId: "580410782277",
            appId: "1:580410782277:web:bc92e54a6cace1f88c292c",
            measurementId: "G-BWFCQJ43VB"
        },
        fbDbRef : "/TVSider",
        fbDbCol : "TVSider",
    }
}