import React, { Component, createRef } from 'react'
import { Icon, Card, Image } from 'semantic-ui-react'
import './App.css';
import {firebaseAuth, fbDbRef, fbDbCol, firebase} from './scripts/firebase';
import {auth} from './scripts/firebaselogin';

import _ from 'lodash';

export class App extends React.Component {
  
  constructor(props){
    super();

    this.state = {
      tvChannels: [] 
    }
    this.contextRef = createRef();
  }

  componentDidMount(){
    console.log("Did Mount");
    firebaseAuth.signInAnonymously();

    fbDbRef.child("DataInput").child("1hmGXsJcaD_17SivzHB63Pv0N6z-BB9iW_7P0HnGrdYA").child("TVChannels").once("value", (snap) => {
      console.log(snap.val());
      
      let items = [];
      _.forEach(snap.val(), (item)=>{
        console.log(item);
        items.push(item);
      })
      this.setState({tvChannels: items});
    })
  }

  getIcon(item){
    let ret = "";

    console.log(item.Category);
    switch(item.Category){
      case "Barn":
        ret = "child";
        break;
      case "Film":
        ret = "film";
        break;
      default:
        break;
    }
    return ret;
  }

  render() {
    return (
      <div className="App">
        
        <div 
          style={styles.jumbotron}
        >
          <div
            style={{
              display: 'flex',
              flexDirection : 'column',
              
            }}
          >
            <div>
              TVSider
            </div>
            <div
              style={{
                fontSize: '0.6em'
              }}
            >
              Live Stream
            </div>

            
          </div>
        </div>
        
        <div
          style={{
            
            paddingTop: '16vh',
          }}
        > 
          <Card.Group 
            stackable={true}
            style={{
              marginTop: 10,
              marginLeft: 10,
              marginRight: 10,
              paddingBottom: 10

            }}
          >
            {this.state.tvChannels.map((item, key) =>{     
              return (
                <Card 
                  key={key}
                  
                >
                  <Icon 
                      name={this.getIcon(item)}
                      style={{
                        position: 'absolute',
                        right: 0,
                        top: 5
                      }}
                      size="large"
                    />
                  <Card.Content
                    style={{
                      minHeight: 70
                      
                    }}
                  >
                  
                    
                     <a href={item.Url}>
                    {item.ImageUrl ? 
                        <Image src={item.ImageUrl}  height={60} />      
                      :
                      <div
                        style={{
                          fontSize: 24,
                          display: 'flex',
                          flexDirection: 'center',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          fontWeight: 'bold',
                          minHeight: 70
                        }}
                      >
                        <div>
                          {item.Title}  
                        </div>
                      </div>
                    }
                    </a>
                  </Card.Content>
                  
                </Card>
              )
            }) }
          </Card.Group>
          <div
              style={{
                fontSize: '0.6em',
                position: 'fixed',
                top: '12vh',
                color: 'white',
                marginLeft: 10,
                zIndex: 3,
                right: 20
              }}
            >
              <a 
                href="https://andersensoftware.no">utviklet av Andersen software
              </a>
            </div>
        </div>
      </div>
    );
  }
}

const styles = {
  jumbotron: {
    height: '15vh',
    backgroundColor: 'black',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2em',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2
  }
}

export default App;
